import { useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import dayjs, { Dayjs } from 'dayjs';
import { FullMenuBoard } from './FullMenu/FullMenuBoard';
import { PersonsFoodBoard } from './PersonsFood/PersonsFoodBoard';
import { CurrentMenuBoard } from './CurrentMenu/CurrentMenuBoard';
import { OrdersBoard } from './Orders/OrdersBoard';
import { FoodTypeTable } from './FoodType/FoodTypeTable';
import Page from '~/components/Shared/Layout/Page/Page';
import Heading from '~/components/Shared/Heading/Heading';
import { FoodForm, FoodFormValues, FoodItem } from '~/components/FoodForm';
import { Button, Notice } from '~/ui';
import { GrayBanner } from '~/ui/GrayBanner/GrayBanner';
import * as Tabs from '@radix-ui/react-tabs';
import '~/assets/css/datepicker-announcement.css';
import { useLocalStorage } from '~/utils/useLocalStorage';
import cn from 'classnames';
import styles from './EditFood.module.scss';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { useTranslation } from 'react-i18next';
import CustomQuerySelect from '~/components/Shared/CustomQuerySelect/CustomQuerySelect';

const EditFood = (): JSX.Element => {
  const { user } = useContext(AuthenticationContext);
  const isAssetEditable = checkIfUserSuperAdmin(user?.data.role_name);
  const { foodID } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState('menu');

  useEffect(() => {
    const path = location.pathname.split('/');
    if (path.length === 4) {
      setActiveTab(path.pop()!);
    } else if (path.length === 3) {
      setActiveTab('menu');
    }
  }, [location.pathname]);

  const { storedInitialValues, create } = useLocalStorage<{
    startDate?: Dayjs | null;
    endDate?: Dayjs | null;
  }>({
    initialValues: {
      startDate: dayjs(),
      endDate: dayjs(),
    },
    key: 'edit-food',
    exclude: [],
  });
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs(storedInitialValues.startDate) || dayjs()
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(
    dayjs(storedInitialValues.endDate) || dayjs()
  );

  const currentDate = dayjs().format('YYYY-MM-DD');
  const [isReportSendLoading, setIsReportSendLoading] =
    useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<
    { id: number; name: string }[]
  >([]);

  const handleDateChange = ([startDate, endDate]: [
    Dayjs | null,
    Dayjs | null,
  ]) => {
    setStartDate(startDate);
    setEndDate(endDate);
    create({
      startDate: startDate,
      endDate: endDate,
    });
  };
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();

  const handleTabChange = (value: string) => {
    setActiveTab(value);
    navigate(`/food/${foodID}/${value}`);
  };

  const client = useQueryClient();

  const { mutateAsync: editFood } = useMutation(
    async ({
      name,
      description,
      address,
      is_active,
      assets,
      food_accommodation,
      uploadPhotosValue,
      pointType,
      timezone,
    }: FoodFormValues) => {
      const deletedPhotoIds = foodItem?.photos
        ?.filter(
          item => !uploadPhotosValue.photos.find(photo => photo.id === item.id)
        )
        .map(photo => photo.id);
      const response = await instance.postForm(`food-admin/${foodID}`, {
        id: foodID,
        name,
        description,
        accommodation_ids: food_accommodation.map(
          accommodation => accommodation.id
        ),
        address,
        ...(is_active && { is_active: is_active?.id }),
        asset_id: assets.id,
        image: uploadPhotosValue.files,
        deletedPhotoIds,
        object_type_id: pointType,
        timezone: timezone.id,
      });
      return response.data;
    },
    {
      onSuccess: (data: FoodFormValues) => {
        client.setQueryData(['food'], (oldFood?: FoodFormValues[]) => {
          if (!oldFood) return oldFood;

          const updatedFood = oldFood.map(foodItem => {
            if (foodItem.id === data.id) {
              return data;
            }
            return foodItem;
          });

          return updatedFood;
        });
        client.setQueryData(['food-item', data.id], data);
        toastSuccess(t('food_point_edited'));
        navigate('/food');
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const {
    data: foodItem,
    isLoading,
    isFetching,
  } = useQuery<FoodItem>({
    queryFn: async () => {
      const response = await instance.get(`food-admin/${foodID}`);
      return response.data;
    },
    queryKey: ['food-item', foodID],
    onError: error => {
      toastError(error);
    },
  });

  const sendReport = async () => {
    setIsReportSendLoading(true);
    try {
      if (!selectedUser.length) {
        toastError(`${t('recipient_list_empty')}!`);
        throw new Error(t('recipient_list_empty'));
      }
      await instance.post('admin/food-send-mail', {
        person_ids: selectedUser.map(selectedUser => selectedUser.id),
        dates: [startDate?.format('YYYY-MM-DD'), endDate?.format('YYYY-MM-DD')],
      });
      toastSuccess(t('report_sent_successfully'));
      setSelectedUser([]);
    } catch (error) {
      toastError(t('error_sending_report'));
    } finally {
      setIsReportSendLoading(false);
    }
  };

  return (
    <Page
      heading={
        <Heading
          text={foodItem?.name}
          actions={
            activeTab === 'orders' && (
              <Notice text={`${t('current_orders_can_before')} 19:00`} />
            )
          }
        />
      }
    >
      <Tabs.Root
        className={styles.tabsRoot}
        value={activeTab}
        onValueChange={handleTabChange}
      >
        <div className={styles.tabsWrapper}>
          <Tabs.List
            className={styles.tabsList}
            aria-label="Manage your account"
          >
            <Tabs.Trigger
              value="menu"
              className={cn(styles.tabsTrigger, {
                [styles.tabsTriggerActive]: activeTab === 'menu',
              })}
            >
              {t('menu')}
            </Tabs.Trigger>
            <Tabs.Trigger
              className={cn(styles.tabsTrigger, {
                [styles.tabsTriggerActive]: activeTab === 'info',
              })}
              value="info"
            >
              {t('general_information')}
            </Tabs.Trigger>
            <Tabs.Trigger
              value="types"
              className={cn(styles.tabsTrigger, {
                [styles.tabsTriggerActive]: activeTab === 'types',
              })}
            >
              {t('food_categories')}
            </Tabs.Trigger>
            <Tabs.Trigger
              className={cn(styles.tabsTrigger, {
                [styles.tabsTriggerActive]: activeTab === 'persons',
              })}
              value="persons"
            >
              {t('responsible_persons')}
            </Tabs.Trigger>
            <Tabs.Trigger
              className={cn(styles.tabsTrigger, {
                [styles.tabsTriggerActive]: activeTab === 'current',
              })}
              value="current"
            >
              {t('current_menu')}
            </Tabs.Trigger>
            <Tabs.Trigger
              className={cn(styles.tabsTrigger, {
                [styles.tabsTriggerActive]: activeTab === 'orders',
              })}
              value="orders"
            >
              {t('orders')}
            </Tabs.Trigger>
          </Tabs.List>
        </div>

        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="menu"
        >
          <div className={styles.editFoodMenuWrapper}>
            {foodID && (
              <FullMenuBoard
                foodId={Number(foodID)}
                currentDate={currentDate}
              />
            )}
          </div>
        </Tabs.Content>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="info"
        >
          <FoodForm
            isLoading={isLoading || isFetching}
            isAssetEditAvailable={isAssetEditable}
            foodItem={foodItem}
            onSubmit={values => editFood(values)}
          />
        </Tabs.Content>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="types"
        >
          <FoodTypeTable foodTypes={foodItem?.food_types} />
        </Tabs.Content>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="persons"
        >
          {foodID && <PersonsFoodBoard foodId={Number(foodID)} />}
        </Tabs.Content>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="current"
        >
          <GrayBanner
            text={t('only_selected_dishes_displayed')}
            className={styles.currentMenuBanner}
          />

          {foodID && (
            <>
              <CurrentMenuBoard
                foodId={Number(foodID)}
                foodPointType={foodItem?.object_type_id || 0}
              />
            </>
          )}
        </Tabs.Content>
        <Tabs.Content
          tabIndex={undefined}
          className={styles.tabsContent}
          value="orders"
        >
          <GrayBanner
            text={t('orders_sent_info')}
            className={styles.currentMenuBanner}
          />

          {foodID && (
            <>
              <OrdersBoard
                dates={[startDate, endDate]}
                handleDateChange={handleDateChange}
                isAutosendOn={foodItem?.active_autosend || false}
              />
            </>
          )}
          <div className={styles.orderSend}>
            <Button
              onClick={sendReport}
              type="submit"
              isLoading={isReportSendLoading}
              text={t('send_orders')}
              className={styles.sendBtn}
              disabled={isReportSendLoading}
            />

            <CustomQuerySelect
              label={t('select_recipient')}
              placeholder={t('choose')}
              value={selectedUser}
              query="admin/food-person"
              queryKey={['food-persons', foodID]}
              onChange={value => {
                if (Array.isArray(value)) {
                  setSelectedUser(value);
                }
              }}
              params={{
                food_id: foodID,
                sort: '',
                perPage: 15,
                field: '',
                page: 1,
              }}
              required
              multiselect
            />
          </div>
        </Tabs.Content>
      </Tabs.Root>
    </Page>
  );
};

export default EditFood;
