import { useFormik } from 'formik';
import CustomQuerySelect from '~/components/Shared/CustomQuerySelect/CustomQuerySelect';
import { useDebounce } from '@uidotdev/usehooks';
import { DatePicker } from 'antd';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';
import { useToastError } from '~/utils/useToastError';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { IUsersFilters } from '~/utils/types/userFilterTypes';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { validate } from '~/utils/validateEmployeesFilter';
import styles from './EmployeesFilter.module.scss';
import { FilterButtons } from '../FiltersButtons/FilterButtons';
import { useEffect, useState } from 'react';
import { FilterErrorMessage } from '../FilterErrorMessage/FilterErrorMessage';

interface IEmployeProps {
  isUsersLoading: boolean;
  selectedUsers: number[];
  onClick: (filters: IUsersFilters) => void;
  setIsStatusUsersModalOpen: () => void;
}

interface IAccomondation {
  id: number;
  name: string;
  full_name?: string;
}

interface IOrganization {
  id: number;
  name: string;
}

interface IFormik {
  accommodation_ids: IAccomondation[];
  announcement_organizations: IOrganization[];
  dates: {
    start_date: string;
    end_date: string;
  };
  user: {
    status: { id: number; name: string; display_name: string };
    type: {
      type: string;
      id: number;
      name: string;
      display_name?: string;
      full_name?: string;
    }[];
  };
}

const capitalizeFirstLetter = (text: string | any) => {
  const lowercased = text.trim().toLowerCase();
  return lowercased.charAt(0).toUpperCase() + lowercased.slice(1);
};

export const EmployeesFilter = ({
  isUsersLoading,
  onClick,
  selectedUsers,
  setIsStatusUsersModalOpen,
}: IEmployeProps) => {
  const [isAnyFieldFilled, setIsAnyFieldFilled] = useState(false);
  const toastError = useToastError();
  const debouncedSearchTerm = useDebounce('', 500);

  const { data } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/users-grade');
      return response.data;
    },
    queryKey: ['grades'],
    onError: error => {
      toastError(error);
    },
  });

  const { data: companies } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/user-company');
      return response.data;
    },
    queryKey: ['user-company'],
    onError: error => {
      toastError(error);
    },
  });

  const getModuleOptions = (data: { [key: number]: string }) => {
    if (!data) return [];
    const modulesOptions = Object.entries(data);

    return modulesOptions.map(item => ({
      id: parseInt(item[0], 10),
      name: item[1],
      display_name: item[1],
    }));
  };

  const companyModules = getModuleOptions(companies?.companies);

  const grades = data?.grade;

  const formik = useFormik<IFormik>({
    initialValues: {
      accommodation_ids: [],
      announcement_organizations: [],
      dates: {
        start_date: '',
        end_date: '',
      },
      user: {
        status: { id: 1, name: '2', display_name: `${t('all_user')}` },
        type: [],
      },
    },
    validate,
    onSubmit: values => {
      const accommodation_ids = values.accommodation_ids.map(item => item.id);
      const company_name = values.announcement_organizations.map(
        item => item.name
      );
      const grade_type = values.user.type.map(item => item.type);
      const filter = {
        ...((values.dates.start_date && values.dates.end_date) ||
        values.accommodation_ids
          ? {
              business_trips: {
                ...(values.accommodation_ids.length !== 0 && {
                  accommodation_ids: accommodation_ids,
                }),
                ...(values.dates.end_date &&
                  values.dates.start_date && {
                    dates: {
                      start_date: values.dates.start_date,
                      end_date: values.dates.end_date,
                    },
                  }),
              },
            }
          : {}),
        ...(values.announcement_organizations.length !== 0
          ? {
              companies: {
                names: company_name,
              },
            }
          : {}),
        users: {
          ...((values.user.status.name === '1' ||
            values.user.status.name === '0') && {
            is_blocked: Number(values.user.status.name),
          }),
          ...(values.user.type.length !== 0 && {
            grade_type: grade_type,
          }),
        },
      };
      onClick(filter);
    },
  });

  const handleResetForm = () => {
    formik.resetForm();
    onClick({});
  };

  useEffect(() => {
    const isFilled =
      !!formik.values.dates.start_date ||
      !!formik.values.dates.end_date ||
      !!formik.values.accommodation_ids.length ||
      !!formik.values.announcement_organizations.length ||
      !!formik.values.user.type.length ||
      formik.values.user.status.name !== '2';
    setIsAnyFieldFilled(isFilled);
  }, [formik.values]);

  return (
    <div className={styles.employersContainer}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.formWrapper}>
          <div className={styles.placeWrapper}>
            <div className={styles.palce}>
              <CustomQuerySelect
                label={t('accommodation_facility')}
                onChange={value => {
                  formik.setFieldValue('accommodation_ids', value);
                }}
                value={formik.values.accommodation_ids}
                query="admin/accommodations"
                placeholder={t('accommodations_validate')}
                isSearchEnabled
                queryKey={['announcement_accommodations', debouncedSearchTerm]}
                params={{
                  sort: '',
                  perPage: 100,
                  field: '',
                  page: 1,
                }}
                multiselect
              />
            </div>
            <div className={styles.palce}>
              <CustomSelect
                label={t('company')}
                options={companyModules}
                value={formik.values.announcement_organizations}
                onChange={value => {
                  formik.setFieldValue('announcement_organizations', value);
                }}
                placeholder={t('validate_organizations')}
                disabled={isUsersLoading}
                multiselect
              />
            </div>
          </div>
          <div className={styles.dateWrapper}>
            <div className={styles.dateBox}>
              <span className={styles.dateLabel}>
                {capitalizeFirstLetter(t('business_trip_start_date'))}
              </span>
              <div className={styles.dateBox}>
                <DatePicker
                  name="start_date"
                  className="datepicker"
                  format="DD.MM.YYYY"
                  value={
                    formik.values.dates.start_date
                      ? dayjs(formik.values.dates.start_date)
                      : null
                  }
                  onChange={date => {
                    formik.setFieldValue(
                      'dates.start_date',
                      date
                        ? dayjs(date)
                            .hour(0)
                            .minute(0)
                            .second(1)
                            .format('YYYY-MM-DD HH:mm:ss')
                        : null
                    );
                  }}
                  placeholder={t('date')}
                  disabledDate={(current: Dayjs) =>
                    formik.values.dates.end_date
                      ? current &&
                        current >
                          dayjs(formik.values.dates.end_date).endOf('day')
                      : false
                  }
                  onBlur={() =>
                    formik.setFieldTouched('dates.start_date', true)
                  }
                />
                {formik.errors.dates?.start_date && (
                  <FilterErrorMessage
                    message={formik.errors.dates?.start_date}
                  />
                )}
              </div>
            </div>
            <div className={styles.dateBox}>
              <span className={styles.dateLabel}>
                {capitalizeFirstLetter(t('business_trip_end_date'))}
              </span>
              <div className={styles.dateBox}>
                <DatePicker
                  name="end_date"
                  className="datepicker"
                  format="DD.MM.YYYY"
                  value={
                    formik.values.dates.end_date
                      ? dayjs(formik.values.dates.end_date)
                      : null
                  }
                  onChange={date => {
                    formik.setFieldValue(
                      'dates.end_date',
                      date
                        ? dayjs(date).endOf('day').format('YYYY-MM-DD HH:mm:ss')
                        : null
                    );
                  }}
                  placeholder={t('date')}
                  disabledDate={(current: Dayjs) =>
                    formik.values.dates.start_date
                      ? current &&
                        current <
                          dayjs(formik.values.dates.start_date).startOf('day')
                      : false
                  }
                  onBlur={() => formik.setFieldTouched('dates.end_date', true)}
                />
                {formik.errors.dates?.end_date && (
                  <FilterErrorMessage message={formik.errors.dates?.end_date} />
                )}
              </div>
            </div>
          </div>
          <div className={styles.typeWrapper}>
            <CustomSelect
              label={t('user_type')}
              options={grades}
              value={formik.values.user.type}
              onChange={value => {
                formik.setFieldValue('user.type', value);
              }}
              placeholder={t('validate_user_type')}
              disabled={isUsersLoading}
              multiselect
            />
            <CustomSelect
              label={t('user_status')}
              options={[
                { id: 1, name: '2', display_name: `${t('all_user')}` },
                { id: 2, name: '0', display_name: `${t('active_user')}` },
                { id: 3, name: '1', display_name: `${t('blocked_user')}` },
              ]}
              value={formik.values.user.status}
              onChange={value => {
                formik.setFieldValue('user.status', value);
              }}
              placeholder={t('validate_user_status')}
              disabled={isUsersLoading}
            />
          </div>
        </div>
        <FilterButtons
          setIsModalOpen={setIsStatusUsersModalOpen}
          isAnyFieldFilled={isAnyFieldFilled}
          selectedUsers={selectedUsers}
          resetForm={handleResetForm}
          isLoading={isUsersLoading}
        />
      </form>
    </div>
  );
};
