import { useEffect, useMemo, useState } from 'react';
import Dropdown from '~/components/Shared/Dropdown';
import Actions, { Action } from '~/ui/Actions';
import styles from './CurrentMenuOptions.module.scss';
import AddIcon from '~/assets/svg/newSvg/actions/add.svg?react';
import { UploadMenuFile } from '../UploadMenuFile';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import dayjs from 'dayjs';
import { useToastError } from '~/utils/useToastError';
import DeleteIcon from '~/assets/svg/newSvg/bin-icon.svg?react';
import { Modal } from '~/components/Shared/Modal/Modal';
import ConfirmDeleteContent from './ConfirmDeleteContent/ConfirmDeleteContent';
import { useTranslation } from 'react-i18next';
import { useToastSuccess } from '~/utils/useToastSuccess';

interface XlsFoodExportActions {
  foodId?: number;
  selectedDate: number;
  foodPointType: number;
  setIsOpen: (isOpen: boolean) => void;
  setIsFoodMenuLoading: (isLoad: boolean) => void;
}

interface UploadFileValue {
  files: File[];
  food_id?: number;
  date: string;
}

const CurrentMenuOptions = ({
  foodId,
  selectedDate,
  foodPointType,
  setIsOpen,
  setIsFoodMenuLoading,
}: XlsFoodExportActions) => {
  const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const toastError = useToastError();
  const toastSuccess = useToastSuccess();
  const { t } = useTranslation();

  const currentDate = dayjs().format('YYYY-MM-DD');
  const nextDay = dayjs().add(1, 'day').format('YYYY-MM-DD');
  const dates = [
    { id: 1, name: currentDate },
    { id: 2, name: nextDay },
    { id: 3, name: dayjs().add(2, 'day').format('YYYY-MM-DD') },
    { id: 4, name: dayjs().add(3, 'day').format('YYYY-MM-DD') },
    { id: 5, name: dayjs().add(4, 'day').format('YYYY-MM-DD') },
    { id: 6, name: dayjs().add(5, 'day').format('YYYY-MM-DD') },
    { id: 7, name: dayjs().add(6, 'day').format('YYYY-MM-DD') },
  ];

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const client = useQueryClient();

  const actualDate = useMemo(() => {
    if (!selectedDate || !dates) return '';

    const dateObj = dates.find(date => date.id === selectedDate);
    return dateObj?.name || '';
  }, [selectedDate]);

  const { mutate: uploadMenu, isLoading } = useMutation(
    async ({ files, food_id, date }: UploadFileValue) => {
      const response = await instance.postForm('admin/food-wares-upload', {
        files,
        food_id,
        ...(foodPointType !== 2 ? { date } : {}),
      });
      return response.data;
    },
    {
      onSuccess: () => {
        client.invalidateQueries(['food-wares', foodId]);
        setSelectedFile(null);
        setIsOpen(false);
        setIsFoodMenuLoading(true);
        toastSuccess(t('current_menu_has_loaded'));
      },
      onError: () => {
        setSelectedFile(null);
      },
    }
  );

  const deleteAllFoods = async () => {
    try {
      await instance.delete('admin/food-wares-all/', {
        data: {
          ...(foodPointType !== 2 ? { date: actualDate } : {}),
          food_id: foodId,
        },
      });
      client.invalidateQueries(['food-wares', foodId]);
      closeModal();
    } catch (error) {
      toastError(error);
    }
  };

  useEffect(() => {
    if (!selectedFile) return;

    uploadMenu({
      files: selectedFile,
      food_id: foodId,
      date: actualDate,
    });
  }, [selectedFile]);

  return (
    <>
      <Dropdown caption={t('actions')}>
        {closeDropdown => (
          <Actions>
            <Action variant="success" onClick={() => setIsOpen(true)}>
              <AddIcon /> {t('add_dish')}
            </Action>
            <Action className={styles.action}>
              <UploadMenuFile
                selectedFile={selectedFile}
                onChange={(uploadedFile: File[]) => {
                  setSelectedFile(uploadedFile);
                }}
                isLoading={isLoading}
              />
            </Action>
            <Action
              variant="danger"
              onClick={() => {
                openModal();
                closeDropdown();
              }}
            >
              <DeleteIcon className={styles.deleteIcon} />{' '}
              {t('delete_all_dishes')}
            </Action>
          </Actions>
        )}
      </Dropdown>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ConfirmDeleteContent
          clickDelete={deleteAllFoods}
          clickCancel={closeModal}
        />
      </Modal>
    </>
  );
};

export default CurrentMenuOptions;
