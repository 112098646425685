import { Button } from '~/ui';
import { t } from 'i18next';
import cn from 'classnames';
import styles from './FiltersButtons.module.scss';

interface IFilterButtonsProps {
  isAnyFieldFilled: boolean;
  selectedUsers: number[];
  resetForm: () => void;
  setIsModalOpen: () => void;
  isLoading?: boolean;
}

export const FilterButtons = ({
  isLoading,
  isAnyFieldFilled,
  resetForm,
  setIsModalOpen,
  selectedUsers,
}: IFilterButtonsProps) => {
  return (
    <div className={styles.buttonWrapper}>
      <div className={styles.mainButtons}>
        <Button
          text={t('apply_filter')}
          type="submit"
          className={styles.button}
          disabled={!isAnyFieldFilled || isLoading}
          isLoading={isLoading}
        />
        {isAnyFieldFilled && (
          <Button
            text={t('discard')}
            type="button"
            className={styles.resetButton}
            onClick={resetForm}
          />
        )}
      </div>
      {selectedUsers.length !== 0 && (
        <Button
          text={t('change_status_of_selected_employees')}
          type="button"
          onClick={setIsModalOpen}
          className={cn(styles.button, styles.statusButton)}
        />
      )}
    </div>
  );
};
