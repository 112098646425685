import {
  IsSort,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableHead,
  TableRow,
} from '~/ui/Table';
import { IconButtonLink } from '~/ui';
import Edit from '~/assets/svg/newSvg/edit.svg?react';
import styles from './FullMenuTable.module.scss';
import { renderPrice, sanitizeMeasure } from '../utils';
import { Photo } from '~/components/UploadPhoto/UploadPhoto';
import { useState } from 'react';
import { Modal } from '~/components/Shared/Modal/Modal';
import NoImageIcon from '~/assets/svg/newSvg/empty-image.svg?react';
import cn from 'classnames';
import ImageContainer from './ImageContainer/ImageContainer';
import TooltipText from '~/components/Shared/TooltipText/TooltipText';
import { useTranslation } from 'react-i18next';

interface MenuItem {
  id: number;
  wares_name: string;
  price_list: { price: string; price_list_name: string };
  weight: string;
  dish_type: string;
  code: string;
  photos: Photo[];
  measure: string;
}

interface FeedbacksTableProps {
  menu: MenuItem[];
  sort: IsSort;
  setSort: ({ field, direction }: IsSort) => void;
  foodId: number;
}

export const FullMenuTable = ({
  menu,
  sort,
  setSort,
  foodId,
}: FeedbacksTableProps): JSX.Element => {
  const [selectedImage, setSelectedImage] = useState<string>('');
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const clickPreviewImage = (image: string) => {
    setModalIsOpen(true);
    setSelectedImage(image);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage('');
  };

  return (
    <Table>
      <TableHead sort={sort} setSort={setSort}>
        <TableCellHead field="wares_name">{t('name')}</TableCellHead>
        <TableCellHead field="code">{t('dish_code')}</TableCellHead>
        <TableCellHead field="price_list">{t('price_list')}</TableCellHead>
        <TableCellHead field="price">{t('price')}</TableCellHead>
        <TableCellHead field="weight">{t('measure')}</TableCellHead>
        <TableCellHead field="photo" sortable={false}>
          {t('Photo')}
        </TableCellHead>
        <TableCellHead sortable={false} field="actions">
          {t('action')}
        </TableCellHead>
      </TableHead>
      <TableBody>
        {menu.map(
          ({
            id,
            wares_name,
            code,
            price_list,
            weight,
            photos,
            measure,
          }: MenuItem) => (
            <TableRow key={id}>
              <TableCell className={styles.foodCell}>
                <TooltipText text={wares_name} />
              </TableCell>
              <TableCell className={styles.foodCell}>{code}</TableCell>
              <TableCell className={styles.foodCell}>
                <TooltipText text={price_list?.price_list_name} />
              </TableCell>
              <TableCell className={styles.foodCell}>
                {renderPrice(price_list?.price)}
              </TableCell>
              <TableCell className={cn(styles.foodCell, styles.weightCell)}>
                <span>
                  {sanitizeMeasure(weight)} {measure.toLowerCase()}
                </span>
              </TableCell>
              <TableCell className={styles.foodCellImage}>
                {photos?.length ? (
                  <ImageContainer
                    onClick={() => clickPreviewImage(photos[0]?.full_photo_url)}
                    image={photos[0]?.full_photo_url}
                  />
                ) : (
                  <div className={styles.foodCellImageEmpty}>
                    <NoImageIcon />
                  </div>
                )}
              </TableCell>
              <TableCell>
                <div className={styles.editFoodTableActions}>
                  <IconButtonLink
                    to={`/food/${foodId}/menu/${id}`}
                    className={styles.editFoodTableBtnLink}
                  >
                    <Edit />
                  </IconButtonLink>
                </div>
              </TableCell>
            </TableRow>
          )
        )}
      </TableBody>
      <Modal
        modalStyles={styles.foodModal}
        isOpen={modalIsOpen}
        onClose={closeModal}
        isCloseButtonShown
      >
        <img className={styles.foodModalImage} src={selectedImage} />
      </Modal>
    </Table>
  );
};
