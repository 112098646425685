import i18next from 'i18next'
import { numberValidate } from './regex'

export const validate = values => {

    const errors = {}

    //date
    if (!values.dates.start_date && values.dates.end_date) {
        const start_date = i18next.t('specify_start_date')
        errors.dates = { start_date }
    } else if (values.dates.start_date && !values.dates.end_date) {
        const end_date = i18next.t('specify_end_date')
        errors.dates = { end_date }
    }

    //average
    if (values.feedback.average && !numberValidate(values.feedback.average)) {
        errors.feedback = { average: i18next.t('value_must_be_number') }
    } else if (values.feedback.condition.name && !values.feedback.average) {
        errors.feedback = { average: i18next.t('enter_value') }
    }

    //condition
    if (values.feedback.average && !values.feedback.condition.name || !values.feedback.condition.name && !!values.category_ids.length) {
        errors.feedback = { condition: { name: i18next.t('select_operator') } }
    }

    // category
    if (!values.category_ids.length && values.feedback.condition.name) {
        errors.category_ids = i18next.t('add_categories')
    }

    return errors
}
