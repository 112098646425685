import styles from './DashBoardPageActions.module.scss';
import { Button } from '~/ui';
import cn from 'classnames';
import dayjs, { Dayjs } from 'dayjs';
import { Checkbox, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import CustomQuerySelect from '~/components/Shared/CustomQuerySelect/CustomQuerySelect';
import { useToastError } from '~/utils/useToastError';
import FilterDropdown from '~/components/Shared/Dropdown/FilterDropdown';
import { useFormik } from 'formik';
import { AuthenticationContext } from '~/components/Authentication/AuthenticationProvider';
import { checkIfUserSuperAdmin } from '~/utils/getUserRole';
import { instance } from '~/utils/api/api';
import { useQuery } from '@tanstack/react-query';
import CalendarIcon from '~/assets/svg/newSvg/aside/calendar.svg?react';
import AssetIcon from '~/assets/svg/newSvg/aside/case.svg?react';

const END_DATES = {
  START_TODAY: dayjs().format('YYYY-MM-DD 00:00:01'),
  START_WEEK: dayjs().subtract(1, 'week').format('YYYY-MM-DD 00:00:01'),
  START_MONTH: dayjs().subtract(1, 'month').format('YYYY-MM-DD 00:00:01'),
};

interface IAssets {
  id: number;
  name: string;
}

interface IFormik {
  start_date: null | Dayjs;
  end_date: null | Dayjs;
  assets_ids: IAssets[] | [];
}

const { RangePicker } = DatePicker;

const DashBoardPageActions = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onClick,
}: {
  startDate: string;
  endDate: string;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  onClick: (array: [] | number[]) => void;
}) => {
  const [isOpenFilter, setIsOpenFilter] = useState(true);
  const [isAllAssedChecked, setIsAllAssedChecked] = useState<boolean>(false);
  const [assets, setAssets] = useState<IAssets[] | []>([]);
  const { user } = useContext(AuthenticationContext);
  const isSuperAdmin = checkIfUserSuperAdmin(user?.data.role_name);
  const { t } = useTranslation();
  const toastError = useToastError();

  const { data: assetsArr } = useQuery({
    queryFn: async () => {
      const { data } = await instance.get('admin/assets');
      return data.data;
    },
    queryKey: ['assets'],
    onError: error => {
      toastError(error);
    },
  });

  const formik = useFormik<IFormik>({
    initialValues: {
      start_date: dayjs(startDate, 'YYYY-MM-DD'),
      end_date: dayjs(endDate, 'YYYY-MM-DD'),
      assets_ids: assets,
    },
    onSubmit: values => {
      setAssets(formik.values.assets_ids);
      const assetsIds = values.assets_ids.map(item => item.id);
      if (values.start_date && values.end_date) {
        setStartDate(values.start_date.format('YYYY-MM-DD 00:00:01'));
        setEndDate(values.end_date.format('YYYY-MM-DD 23:59:59'));
      }
      onClick(assetsIds);
      setIsOpenFilter(!isOpenFilter);
    },
  });

  useEffect(() => {
    if (!isAllAssedChecked) return;
    formik.setFieldValue('assets_ids', []);
  }, [isAllAssedChecked]);

  useEffect(() => {
    if (!assetsArr || isSuperAdmin) return;
    setAssets(assetsArr);
    onClick([assetsArr[0].id]);
    formik.setFieldValue('assets_ids', assetsArr);
  }, [assetsArr]);

  useEffect(() => {
    if (!startDate && !endDate) return;
    formik.setFieldValue('start_date', dayjs(startDate, 'YYYY-MM-DD'));
    formik.setFieldValue('end_date', dayjs(endDate, 'YYYY-MM-DD'));
  }, [startDate, endDate]);

  return (
    <div className={styles.dashboardPageActions}>
      <Button
        text={t('today')}
        className={cn(
          styles.dashboardButton,
          startDate === END_DATES.START_TODAY
            ? styles.dashboardButtonActive
            : ''
        )}
        onClick={() => {
          setStartDate(dayjs().format('YYYY-MM-DD 00:00:01'));
          localStorage.setItem(
            'startDate',
            dayjs().format('YYYY-MM-DD 00:00:01')
          );
          setEndDate(dayjs().format('YYYY-MM-DD 23:59:59'));
          localStorage.setItem(
            'endDate',
            dayjs().format('YYYY-MM-DD 23:59:59')
          );
        }}
      />
      <Button
        text={t('week')}
        className={cn(
          styles.dashboardButton,
          startDate === END_DATES.START_WEEK ? styles.dashboardButtonActive : ''
        )}
        onClick={() => {
          setStartDate(
            dayjs().subtract(1, 'week').format('YYYY-MM-DD 00:00:01')
          );
          localStorage.setItem(
            'startDate',
            dayjs().subtract(1, 'week').format('YYYY-MM-DD 00:00:01')
          );

          setEndDate(dayjs().format('YYYY-MM-DD 23:59:59'));
          localStorage.setItem(
            'endDate',
            dayjs().format('YYYY-MM-DD 23:59:59')
          );
        }}
      />
      <Button
        text={t('month')}
        className={cn(
          styles.dashboardButton,
          startDate === END_DATES.START_MONTH
            ? styles.dashboardButtonActive
            : ''
        )}
        onClick={() => {
          setStartDate(
            dayjs().subtract(1, 'month').format('YYYY-MM-DD 00:00:01')
          );
          localStorage.setItem(
            'startDate',
            dayjs().subtract(1, 'month').format('YYYY-MM-DD 00:00:01')
          );
          setEndDate(dayjs().format('YYYY-MM-DD 23:59:59'));
          localStorage.setItem(
            'endDate',
            dayjs().format('YYYY-MM-DD 23:59:59')
          );
        }}
      />
      <div className={styles.dashboardPageDateWrapper}>
        <CalendarIcon className={styles.dateIcon} />
        <span className={styles.dashboardPageDates}>
          {dayjs(startDate).format('DD.MM.YYYY')}-
          {dayjs(endDate).format('DD.MM.YYYY')}
        </span>
        <div className={styles.assetsWrapper}>
          {!!assets.length && <AssetIcon />}
          {assets
            .map(item => (
              <span key={item.id} className={styles.dashboardPageDates}>
                {item.name}
              </span>
            ))
            .slice(0, 3)}
          {assets.length > 3 && (
            <span className={styles.dashboardPageDates}>{'...'}</span>
          )}
        </div>
      </div>
      <div>
        <FilterDropdown toClose={isOpenFilter}>
          <form
            className={styles.dropdownContainer}
            onSubmit={formik.handleSubmit}
          >
            <span className={styles.dropdownTitle}>{t('filter')}</span>
            <div className={styles.dateWrapper}>
              <span className={styles.dateLabel}>{t('date')}</span>
              <RangePicker
                className={styles.date}
                format="DD.MM.YYYY"
                value={[formik.values.start_date, formik.values.end_date]}
                onChange={dates => {
                  if (dates && dates[0] && dates[1]) {
                    formik.setFieldValue('start_date', dates[0]);
                    formik.setFieldValue('end_date', dates[1]);
                  } else {
                    formik.setFieldValue('start_date', '');
                    formik.setFieldValue('end_date', '');
                  }
                }}
                disabledDate={currentDate => {
                  return (
                    currentDate && currentDate.isAfter(dayjs().endOf('day'))
                  );
                }}
                placeholder={[t('start_date'), t('end_date')]}
                onBlur={() => {
                  formik.setFieldTouched('start_date', true);
                  formik.setFieldTouched('end_date', true);
                }}
              />
            </div>
            <div className={styles.assetsWrapper}>
              <CustomQuerySelect
                label={t('assets')}
                query="admin/assets"
                queryKey={['assets', {}]}
                placeholder={t('validate_asset')}
                params={null}
                value={formik.values.assets_ids}
                onChange={value => {
                  if (Array.isArray(value)) {
                    formik.setFieldValue('assets_ids', value);
                  }
                }}
                required
                disabled={isAllAssedChecked || !isSuperAdmin}
                multiselect
              />
              {isSuperAdmin && (
                <div className={styles.contentWrapper}>
                  <div className={styles.selectAllAssetss}>
                    <Checkbox
                      checked={isAllAssedChecked}
                      onChange={event => {
                        setIsAllAssedChecked(event.target.checked);
                      }}
                    />
                    <span className={styles.checkoxLabel}>
                      {t('all_assets')}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.buttonWrapper}>
              <Button
                type="button"
                text={t('cancel')}
                className={styles.cancelBtn}
                onClick={() => setIsOpenFilter(!isOpenFilter)}
              />
              <Button
                type="submit"
                text={t('ready')}
                className={styles.readyBtn}
                disabled={
                  (!formik.values.start_date &&
                    !formik.values.end_date &&
                    !formik.values.assets_ids.length &&
                    !isAllAssedChecked) ||
                  (!isSuperAdmin &&
                    !formik.values.start_date &&
                    !formik.values.end_date)
                }
              />
            </div>
          </form>
        </FilterDropdown>
      </div>
    </div>
  );
};

export default DashBoardPageActions;
