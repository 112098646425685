import { useParams } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { FormikValues, useFormik } from 'formik';
import { Button, Textarea, ButtonLink } from '~/ui/index';
import styles from './RequestCommentForm.module.scss';
import { useTranslation } from 'react-i18next';
import UploadPhoto, {
  UploadPhotosValue,
} from '~/components/UploadPhoto/UploadPhoto';

interface RequestCommentValues {
  description: string;
  ticket_id: string;
  uploadPhotosValue: UploadPhotosValue;
}

interface IPostCommentValues {
  description: string;
  ticket_id: number;
  images: any[];
}

interface RequestItem {
  tickets: { ticket_comment: RequestCommentValues[]; ticket_id: number };
}

export const RequestCommentForm = (): JSX.Element => {
  const { requestID } = useParams();
  const client = useQueryClient();
  const toastError = useToastError();
  const { t } = useTranslation();

  const { mutate: createComment } = useMutation(
    async ({ description, ticket_id, images }: IPostCommentValues) => {
      const response = await instance.postForm('admin/ticket-comment', {
        description,
        ticket_id: Number(ticket_id),
        images,
      });
      return response.data;
    },
    {
      onSuccess: (data: RequestCommentValues) => {
        client.setQueryData(
          ['request', requestID],
          (oldTicket?: RequestItem) => {
            if (!oldTicket) return oldTicket;

            return {
              ...oldTicket,
              tickets: {
                ...oldTicket.tickets,
                ticket_comment: [...oldTicket.tickets.ticket_comment, data],
              },
            };
          }
        );
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  const formik = useFormik<RequestCommentValues>({
    initialValues: {
      ticket_id: requestID || '',
      description: '',
      uploadPhotosValue: { photos: [], files: [] },
    },

    validate: values => {
      const errors: Partial<RequestCommentValues> = {};

      if (!values.description) {
        errors.description = 'Description is required';
      }

      return errors;
    },

    onSubmit: (values: FormikValues) => {
      createComment({
        description: values.description,
        ticket_id: values.ticket_id,
        images: values.uploadPhotosValue.files,
      });
      formik.resetForm();
    },
  });

  return (
    <div className={styles.formWrapper}>
      <form
        onSubmit={formik.handleSubmit}
        autoComplete="off"
        className={styles.form}
      >
        <div className={styles.commentWrapper}>
          <span className={styles.label}>{t('comment')}</span>

          <div className={styles.marginWrapper}>
            <UploadPhoto
              onChange={uploadPhotosValue => {
                formik.setFieldValue('uploadPhotosValue', uploadPhotosValue);
              }}
              values={formik.values.uploadPhotosValue}
              label=""
            />
          </div>
          <div className={styles.marginWrapper}>
            <Textarea
              name="description"
              rows={4}
              label=""
              placeholder={t('comment_text')}
              maxLength={1000}
              value={formik.values.description}
              onChange={formik.handleChange}
              star={false}
              disabled={false}
              showCounter
            />
          </div>
        </div>

        <div className={styles.btnsWrapper}>
          <Button
            type="submit"
            text={t('send_comment')}
            className={styles.formBtn}
            disabled={!formik.isValid}
          />

          <ButtonLink
            text={t('back_to_list')}
            to="/requests"
            color="gray"
            className={styles.btnGray}
          />
        </div>
      </form>
    </div>
  );
};
