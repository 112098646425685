import i18next from 'i18next'
import { numberValidate } from './regex'

export const validate = values => {
    const errors = {}

    //date
    if (!values.dates.start_date && values.dates.end_date) {
        const start_date = i18next.t('specify_start_date')
        errors.dates = { start_date }
    } else if (values.dates.start_date && !values.dates.end_date) {
        const end_date = i18next.t('specify_end_date')
        errors.dates = { end_date }
    }

    return errors
}
