import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import dayjs from 'dayjs';
import {
  AnnouncementForm,
  AnnouncementItem,
  AnnouncementValues,
} from '~/components/AnnouncementForm';
import Heading from '~/components/Shared/Heading/Heading';
import Page from '~/components/Shared/Layout/Page/Page';
import styles from './EditAnnouncement.module.scss';
import { useToastSuccess } from '~/utils/useToastSuccess';
import { useTranslation } from 'react-i18next';

const EditAnnouncement = (): JSX.Element => {
  const { announcementID } = useParams();
  const toastError = useToastError();
  const { t } = useTranslation();
  const toastSuccess = useToastSuccess();

  const {
    data: announcement,
    isLoading,
    isFetching,
  } = useQuery<AnnouncementItem>({
    queryFn: async () => {
      const response = await instance.get(
        `admin/announcement/${announcementID}`
      );
      return response.data;
    },
    queryKey: ['announcement', announcementID],
    onError: error => {
      toastError(error);
    },
  });

  const navigate = useNavigate();

  const client = useQueryClient();

  const { mutateAsync: editAnnouncement } = useMutation(
    async ({
      module,
      status_id,
      announcement_accommodations,
      name,
      text,
      start_date,
      end_date,
      photos,
      deletedPhotoIds,
      announcement_organizations,
    }: AnnouncementValues) => {
      const response = await instance.postForm(
        `admin/announcement/${announcementID}?_method=put`,
        {
          module,
          status_id,
          name,
          text,
          start_date: dayjs(start_date)
            .hour(0)
            .minute(0)
            .second(1)
            .format('YYYY-MM-DD HH:mm:ss'),
          end_date: dayjs(end_date).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          image_ids: photos.map(photo => photo.id),
          deletedPhotoIds,
          accommodation_ids: announcement_accommodations.map(
            organization => organization.id
          ),
          organization_ids: announcement_organizations.map(
            organization => organization.id
          ),
        }
      );
      return response.data;
    },
    {
      onSuccess: () => {
        toastSuccess(t('announcement_edited'));
        client.invalidateQueries(['accommodations']);
        navigate('/announcements');
      },
      onError: error => {
        toastError(error);
      },
    }
  );

  return (
    <Page
      heading={
        <Heading
          text={announcement?.name}
          classNameText={styles.editAnnouncementText}
        />
      }
    >
      <AnnouncementForm
        isLoading={isLoading || isFetching}
        announcement={announcement}
        onSubmit={values => editAnnouncement(values)}
      />
    </Page>
  );
};

export default EditAnnouncement;
