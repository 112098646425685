import { useContext, useEffect, useState } from 'react';
import { useToastError } from '~/utils/useToastError';
import { useFormik } from 'formik';
import { validate } from '~/utils/validateCreateFood';
import { Button, InputBar, Textarea, Badge } from '~/ui/index';
import { useLocalStorage } from '~/utils/useLocalStorage';
import { IAsset } from '../AccommodationForm';
import styles from './FoodForm.module.scss';
import { instance } from '~/utils/api/api';
import { AuthenticationContext } from '../Authentication/AuthenticationProvider';
import {
  checkIfUserAssetAdmin,
  checkIfUserSuperAdmin,
} from '~/utils/getUserRole';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
import UploadPhoto, { UploadPhotosValue } from '../UploadPhoto/UploadPhoto';
import { useQuery } from '@tanstack/react-query';
import CustomQuerySelect from '../Shared/CustomQuerySelect/CustomQuerySelect';
import CustomSelect from '../Shared/CustomSelect/CustomSelect';
import { getTimezones } from '../UserForm/timezones';

export interface FoodFormValues {
  id?: number | null;
  name: string;
  description: string;
  address: string;
  is_active: null | {
    id: number;
    name: JSX.Element;
  };
  uploadPhotosValue: UploadPhotosValue;
  foodTypes?: any;
  pointType: null | number;
  pointObject: { id: number; name: string };
  food_accommodation: FoodAccommodation[];
  assets: { id: number; name: string };
  timezone: {
    id: string;
    name: string;
  };
}

interface FoodAccommodation {
  id: number;
  name: string;
  full_name: string;
}
interface Photo {
  id: number;
  full_photo_url: string;
}

export interface FoodItem {
  accommodation_id?: null;
  address: string;
  description: string;
  name: string;
  food_menu_id?: number;
  is_active: null | {
    id: number;
    name: JSX.Element;
  };
  food_accommodation: FoodAccommodation[];
  uploadPhotosValue: UploadPhotosValue;
  food_types?: any;
  food_object_type: { id: number; name: string };
  object_type_id: number;
  photos: Photo[];
  pointType: number;
  assets: IAsset;
  timezone: { id: string; name: string };
  active_autosend: boolean;
}

interface FoodFormProps {
  foodItem?: FoodItem;
  onSubmit: (values: FoodFormValues) => void;
  storageKey?: string;
  isAssetEditAvailable?: boolean;
  isLoading?: boolean;
}

const getStatusOptions = () => {
  return [
    {
      id: 1,
      name: (
        <Badge className={styles.statusBadge} variant="success">
          {t('order_available')}
        </Badge>
      ),
    },
    {
      id: 0,
      name: (
        <Badge className={styles.statusBadge} variant="error">
          {t('order_not_available')}
        </Badge>
      ),
    },
  ];
};

export const FoodForm = ({
  isLoading,
  foodItem,
  onSubmit,
  storageKey,
  isAssetEditAvailable,
}: FoodFormProps): JSX.Element => {
  const { user } = useContext(AuthenticationContext);
  const isHaveNotEnoughRole = !checkIfUserSuperAdmin(user?.data.role_name);
  const isAssetAdmin = !checkIfUserAssetAdmin(user?.data.role_name);
  const toastError = useToastError();
  const [assets, setAssets] = useState<IAsset[]>([]);
  const { t } = useTranslation();
  const [counter, setCounter] = useState<number>(0);

  const timezonesList = getTimezones();

  const loadAssets = async () => {
    try {
      const { data } = await instance.get('admin/assets');
      setAssets(data.data);
    } catch {
      toastError(`${t('failed_to_load')} ${t('assets')}`);
    }
  };

  const { data: pointTypes } = useQuery({
    queryFn: async () => {
      const data = await instance.get('food-admin-object-types');
      return data;
    },
    queryKey: ['food-point-types'],
  });

  const { initialValues, storedInitialValues, clear, create } =
    useLocalStorage<FoodFormValues>({
      initialValues: {
        name: '',
        description: '',
        address: '',
        is_active: null,
        pointType: null,
        pointObject: {
          id: 0,
          name: '',
        },
        assets: {
          id: 0,
          name: '',
        },
        timezone: {
          id: '',
          name: '',
        },
        uploadPhotosValue: { photos: [], files: [] },
        food_accommodation: [],
      },
      key: storageKey && `${storageKey}-v1`,
      exclude: ['uploadPhotosValue', 'is_active'],
    });

  const formik = useFormik<FoodFormValues>({
    initialValues: storedInitialValues,

    validate,

    onSubmit: async (values: FoodFormValues) => {
      try {
        await onSubmit(values);
        clear();
        formik.resetForm({ values: initialValues });
      } catch (error) {
        toastError(error);
      }
    },
  });

  create(formik.values);

  const setValues = formik.setValues;
  useEffect(() => {
    if (!foodItem) return;

    setValues({
      name: foodItem.name,
      description: foodItem.description,
      address: foodItem.address,
      is_active: foodItem.is_active
        ? getStatusOptions()[0]
        : getStatusOptions()[1],
      uploadPhotosValue: { photos: foodItem.photos, files: [] },
      pointObject: foodItem.food_object_type,
      pointType: foodItem.food_object_type?.id,
      food_accommodation: foodItem.food_accommodation,
      assets: foodItem.assets,
      timezone: getTimezones().find(
        timezone => timezone.id === String(foodItem.timezone)
      ) || { id: '', name: '' },
    });
  }, [foodItem, setValues]);

  useEffect(() => {
    loadAssets();
  }, []);

  useEffect(() => {
    if (counter) {
      formik.setFieldValue('food_accommodation', []);
    }
  }, [counter]);

  const isEditingBlocked = foodItem && !formik.values.assets;

  const params = {
    sort: '',
    perPage: 100,
    field: '',
    page: 1,
    asset_id: formik.values.assets?.id,
  };

  return (
    <div className={styles.formWrapper}>
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={formik.handleSubmit}
      >
        <div className={styles.formItems}>
          <CustomSelect
            label={t('food_point_type')}
            value={formik.values.pointObject}
            onChange={value => {
              formik.setFieldValue('pointObject', value);
            }}
            options={pointTypes ? pointTypes?.data : []}
            required
            placeholder={t('select_food_point_type')}
            disabled={isLoading || !!foodItem}
            error={formik.errors.pointObject as string}
          />
          <InputBar
            name="name"
            value={formik.values.name}
            disabled={isLoading}
            label={t('name')}
            star={true}
            placeholder={t('name')}
            onChange={formik.handleChange}
            errors={formik.errors.name}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
          />

          {isAssetEditAvailable && (
            <CustomSelect
              label={t('asset')}
              value={formik.values.assets}
              onChange={value => {
                setCounter(counter + 1);
                formik.setFieldValue('assets', value);
              }}
              options={assets}
              required
              disabled={!isAssetEditAvailable || isLoading}
              error={formik.errors.assets as string}
              placeholder={t('validate_asset')}
            />
          )}
          <div className={styles.addressWrapper}>
            <InputBar
              name="address"
              value={formik.values.address}
              disabled={isLoading}
              label={t('food_address_ourlet')}
              star={true}
              placeholder={t('food_address_ourlet')}
              onChange={formik.handleChange}
              errors={formik.errors.address}
              onBlur={formik.handleBlur}
              error={formik.touched.address && Boolean(formik.errors.address)}
            />
          </div>
          <Textarea
            showCounter
            rows={4}
            label={t('description')}
            placeholder={t('description')}
            maxLength={1000}
            value={formik.values.description}
            onChange={formik.handleChange}
            name="description"
            star={true}
            disabled={isLoading}
            errors={formik.errors.description}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
          />
          <div className={styles.field}>
            <CustomSelect
              label={t('status')}
              value={formik.values.is_active}
              onChange={value => {
                formik.setFieldValue('is_active', value);
              }}
              options={getStatusOptions()}
              placeholder={t('select_status')}
              disabled={isLoading}
            />
          </div>
          <CustomSelect
            label={t('time_zone')}
            options={timezonesList}
            value={formik.values.timezone || ''}
            placeholder={t('timezone_validate')}
            onChange={value => {
              formik.setFieldValue('timezone', value);
            }}
            required
            error={formik.errors.timezone as string}
          />
          <CustomQuerySelect
            label={t('accommodation_facility')}
            onChange={value => {
              formik.setFieldValue('food_accommodation', value);
            }}
            value={formik.values.food_accommodation}
            disabled={
              isEditingBlocked ||
              (isHaveNotEnoughRole && isAssetAdmin) ||
              isLoading
            }
            query="admin/accommodations"
            params={params}
            queryKey={['accommodations-food', params]}
            required
            placeholder={t('to_choose')}
            multiselect
            isSearchEnabled
            error={formik.errors.food_accommodation as string}
          />
          <UploadPhoto
            onChange={uploadPhotosValue => {
              formik.setFieldValue('uploadPhotosValue', uploadPhotosValue);
            }}
            values={formik.values.uploadPhotosValue}
            label={t('Photo')}
            disabled={(isHaveNotEnoughRole && isAssetAdmin) || isLoading}
            isUploadBlocked={isHaveNotEnoughRole && isAssetAdmin}
          />
        </div>
        <Button
          type="submit"
          text={t('save_n_exit')}
          className={styles.formBtn}
          disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
        />
      </form>
    </div>
  );
};
