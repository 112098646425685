import { useEffect, useState } from 'react';
import * as Tabs from '@radix-ui/react-tabs';
import cn from 'classnames';
import { OrderFilter } from './OrderFilter/OrderFilter';
import { EmployeesFilter } from './EmployersFilter/EmployeesFilter';
import styles from './UserFilters.module.scss';
import { ApplicationsFilter } from './ApplicationsFilter/ApplicationsFilter';
import { FeedbackFilters } from './FeedbackFilters/FeedbackFilters';
import { IUsersFilters } from '~/utils/types/userFilterTypes';
import { t } from 'i18next';

interface IUserFiltersProps {
  isUsersLoading: boolean;
  props: any;
  selectedUsers: number[];
  onClick: (value: IUsersFilters) => void;
  setIsStatusUsersModalOpen: () => void;
  setSelectedUsers: ([]) => void;
}

const SORT_STATUS = {
  ORDERS: 'orders',
  USERS: 'users',
  APPLICATIONS: 'applications',
  FEEDBACK: 'feedback',
};

const UserFilters = ({
  isUsersLoading,
  selectedUsers,
  onClick,
  setIsStatusUsersModalOpen,
  setSelectedUsers,
}: IUserFiltersProps) => {
  const [selectedSort, setSelectedSort] = useState('orders');

  useEffect(() => {
    onClick({});
    setSelectedUsers([]);
  }, [selectedSort]);

  return (
    <div className={styles.userFilters}>
      <Tabs.Root
        value={selectedSort}
        onValueChange={value => setSelectedSort(value)}
        className={styles.tabsRoot}
      >
        <Tabs.List>
          <Tabs.Trigger
            value="orders"
            className={cn(styles.tabsTrigger, {
              [styles.tabsTriggerActive]: selectedSort === SORT_STATUS.ORDERS,
            })}
          >
            {t('orders')}
          </Tabs.Trigger>
          <Tabs.Trigger
            value="users"
            className={cn(styles.tabsTrigger, {
              [styles.tabsTriggerActive]: selectedSort === SORT_STATUS.USERS,
            })}
          >
            {t('employees')}
          </Tabs.Trigger>
          <Tabs.Trigger
            value="applications"
            className={cn(styles.tabsTrigger, {
              [styles.tabsTriggerActive]:
                selectedSort === SORT_STATUS.APPLICATIONS,
            })}
          >
            {t('applications')}
          </Tabs.Trigger>
          <Tabs.Trigger
            value="feedback"
            className={cn(styles.tabsTrigger, {
              [styles.tabsTriggerActive]: selectedSort === SORT_STATUS.FEEDBACK,
            })}
          >
            {t('feedback')}
          </Tabs.Trigger>
        </Tabs.List>
      </Tabs.Root>

      {selectedSort === SORT_STATUS.ORDERS && (
        <OrderFilter
          isUsersLoading={isUsersLoading}
          selectedUsers={selectedUsers}
          setIsStatusUsersModalOpen={setIsStatusUsersModalOpen}
          onClick={onClick}
        />
      )}
      {selectedSort === SORT_STATUS.USERS && (
        <EmployeesFilter
          isUsersLoading={isUsersLoading}
          onClick={onClick}
          selectedUsers={selectedUsers}
          setIsStatusUsersModalOpen={setIsStatusUsersModalOpen}
        />
      )}
      {selectedSort === SORT_STATUS.APPLICATIONS && (
        <ApplicationsFilter
          isUsersLoading={isUsersLoading}
          onClick={onClick}
          selectedUsers={selectedUsers}
          setIsStatusUsersModalOpen={setIsStatusUsersModalOpen}
        />
      )}
      {selectedSort === SORT_STATUS.FEEDBACK && (
        <FeedbackFilters
          isUsersLoading={isUsersLoading}
          onClick={onClick}
          selectedUsers={selectedUsers}
          setIsStatusUsersModalOpen={setIsStatusUsersModalOpen}
        />
      )}
    </div>
  );
};

export default UserFilters;
