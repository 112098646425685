import i18next from 'i18next'
import { numberValidate } from './regex'

export const validate = values => {
    const errors = {}

    //date
    if (!values.dates.start_date && values.dates.end_date) {
        const start_date = i18next.t('specify_start_date')
        errors.dates = { start_date }
    } else if (values.dates.start_date && !values.dates.end_date) {
        const end_date = i18next.t('specify_end_date')
        errors.dates = { end_date }
    }

    //order count
    if (values.cancel_orders.count && !numberValidate(values.cancel_orders.count)) {
        errors.cancel_orders = { count: i18next.t('value_must_be_number') }
    } else if (values.cancel_orders.condition.name && !values.cancel_orders.count) {
        errors.cancel_orders = { count: i18next.t('enter_value') }
    } else if (values.cancel_orders.cancel_by.name && values.cancel_orders.condition.name && !values.cancel_orders.count) {
        errors.cancel_orders = { count: i18next.t('enter_value') }
    }

    //wares count    
    if (values.cancel_wares.count && !numberValidate(values.cancel_wares.count)) {
        errors.cancel_wares = { count: i18next.t('value_must_be_number') }
    } else if (values.cancel_wares.condition.name && !values.cancel_wares.count) {
        errors.cancel_wares = { count: i18next.t('enter_value') }
    } else if (values.cancel_orders.cancel_by.name && values.cancel_wares.condition.name && !values.cancel_wares.count) {
        errors.cancel_wares = { count: i18next.t('enter_value') }
    }

    //order condition
    if (values.cancel_orders.count && !values.cancel_orders.condition.name) {
        errors.cancel_orders = { condition: { name: i18next.t('select_operator') } }
    } else if (values.cancel_orders.cancel_by.name && !values.cancel_orders.condition.name && !values.cancel_wares.condition.name) {
        errors.cancel_orders = { condition: { name: i18next.t('select_operator') } }
    }

    //wares condition
    if (values.cancel_wares.count && !values.cancel_wares.condition.name) {
        errors.cancel_wares = { condition: { name: i18next.t('select_operator') } }
    } else if (values.cancel_orders.cancel_by.name && !values.cancel_wares.condition.name && !values.cancel_orders.condition.name) {
        errors.cancel_wares = { condition: { name: i18next.t('select_operator') } }
    }

    //cancel_by
    if (values.cancel_wares.count && values.cancel_wares.condition.name && !values.cancel_orders.cancel_by.name || values.cancel_orders.count && values.cancel_orders.condition.name && !values.cancel_orders.cancel_by.name) {
        errors.cancel_orders = { cancel_by: { name: i18next.t('select_reason') } }
    }

    return errors
}
