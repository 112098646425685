import { useEffect, useRef, useState } from 'react';
import FiltersIcon from '~/assets/svg/newSvg/filter.svg?react';
import s from './Dropdown.module.scss';

interface DropdownProps {
  children: React.ReactNode;
  caption?: string;
  toClose?: boolean;
}

const FilterDropdown = (props: DropdownProps) => {
  const { children, caption, toClose } = props;
  const ref = useRef<HTMLDivElement>(null!);
  const [isOpen, setOpen] = useState(false);

  const close = () => setOpen(false);
  const toggle = () => setOpen(prevState => !prevState);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => e.key === 'Escape' && close();
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    if (typeof toClose !== 'boolean') return;
    setOpen(false);
  }, [toClose]);

  return (
    <div className={s.container} ref={ref}>
      <button type="button" onClick={toggle} className={s.caption}>
        <FiltersIcon />
        {caption && caption}
      </button>
      {isOpen && <div className={s.content}>{children}</div>}
    </div>
  );
};

export default FilterDropdown;
