import i18next from 'i18next'
import { numberValidate } from './regex'

export const validate = values => {
    const errors = {}

    //date
    if (!values.dates.start_date && values.dates.end_date) {
        const start_date = i18next.t('specify_start_date')
        errors.dates = { start_date }
    } else if (values.dates.start_date && !values.dates.end_date) {
        const end_date = i18next.t('specify_end_date')
        errors.dates = { end_date }
    }

    //cancel_orders
    if (values.cancel_orders.condition.name && !values.cancel_orders.count) {
        const count = i18next.t('enter_value')
        errors.cancel_orders = { count }
    } else if (!values.cancel_orders.condition.name && values.cancel_orders.count) {
        const name = i18next.t('select_operator')
        errors.cancel_orders = { condition: { name } }
    } else if (values.cancel_orders.count && !numberValidate(values.cancel_orders.count)) {
        const count = i18next.t('value_must_be_number')
        errors.cancel_orders = { count }
    } else if (values.cancel_orders.cancel_by.name && values.cancel_orders.condition.name && !values.cancel_orders.count) {
        const count = i18next.t('enter_value')
        errors.cancel_orders = { count }
    }

    //cancel_orders-cancel_by
    if (values.cancel_orders.condition.name && values.cancel_orders.count && values.cancel_orders.cancel_by.length === 0) {
        const name = i18next.t('add_categories')
        errors.cancel_orders = { cancel_by: name }
    } else if (values.cancel_orders.cancel_by.name && !values.cancel_orders.count && !values.cancel_orders.condition.name) {
        const count = i18next.t('enter_value')
        errors.cancel_orders = { count }
    } else if (!!values.cancel_orders.cancel_by.length && !values.cancel_orders.condition.name && !values.cancel_orders.count) {
        const name = i18next.t('select_operator')
        errors.cancel_orders = { condition: { name } }
    }

    return errors
}
