import { DatePicker } from 'antd';
import CustomSelect from '~/components/Shared/CustomSelect/CustomSelect';
import { InputBar } from '~/ui';
import { useFormik } from 'formik';
import { useQuery } from '@tanstack/react-query';
import { instance } from '~/utils/api/api';
import { useToastError } from '~/utils/useToastError';
import { IUsersFilters } from '~/utils/types/userFilterTypes';
import { validate } from '~/utils/validatApplicationsFilter';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import styles from './ApplicationsFilter.module.scss';
import { FilterButtons } from '../FiltersButtons/FilterButtons';
import { useEffect, useState } from 'react';
import { FilterErrorMessage } from '../FilterErrorMessage/FilterErrorMessage';

interface IFormik {
  dates: {
    start_date: string;
    end_date: string;
  };
  cancel_orders: {
    cancel_by: { id: number; name: string }[] | [];
    condition: { id: number; name: string; display_name: string };
    count: string;
  };
}

interface IOrderProps {
  isUsersLoading: boolean;
  selectedUsers: number[];
  onClick: (filters: IUsersFilters) => void;
  setIsStatusUsersModalOpen: () => void;
}

export const ApplicationsFilter = ({
  onClick,
  selectedUsers,
  setIsStatusUsersModalOpen,
  isUsersLoading,
}: IOrderProps) => {
  const [isAnyFieldFilled, setIsAnyFieldFilled] = useState(false);
  const toastError = useToastError();
  const { data: notificationModules } = useQuery({
    queryFn: async () => {
      const response = await instance.get('admin/ticket-categories');
      return response.data;
    },
    queryKey: ['ticket-categories'],
    onError: error => {
      toastError(error);
    },
  });

  const getModuleOptions = (data: { id: number; category: string }[]) => {
    if (!data) return [];
    const modulesOptions = data.map(item => ({
      id: item.id,
      name: item.category,
      display_name: item.category,
    }));
    return modulesOptions;
  };

  const modulesOptions = getModuleOptions(notificationModules);

  const formik = useFormik<IFormik>({
    initialValues: {
      dates: {
        start_date: '',
        end_date: '',
      },
      cancel_orders: {
        cancel_by: [],
        condition: { id: 0, name: '', display_name: '' },
        count: '',
      },
    },
    validate,
    onSubmit: values => {
      const category_ids = values.cancel_orders.cancel_by.map(item => item?.id);
      const filter = {
        tickets: {
          ...(values.dates.start_date && values.dates.end_date
            ? {
                dates: {
                  start_date: values.dates.start_date,
                  end_date: values.dates.end_date,
                },
              }
            : {}),
          ...(values.cancel_orders.cancel_by.length !== 0 && {
            category_ids: category_ids,
          }),
          ...(values.cancel_orders.condition.name && {
            condition: values.cancel_orders.condition.name,
          }),
          ...(values.cancel_orders.count && {
            count: values.cancel_orders.count,
          }),
        },
      };

      onClick(filter);
    },
  });

  const handleResetForm = () => {
    formik.resetForm();
    onClick({});
  };

  useEffect(() => {
    const isFilled =
      !!formik.values.dates.start_date ||
      !!formik.values.dates.end_date ||
      !!formik.values.cancel_orders.count ||
      !!formik.values.cancel_orders.condition.name ||
      !!formik.values.cancel_orders.cancel_by.length;

    setIsAnyFieldFilled(isFilled);
  }, [formik.values]);

  return (
    <div className={styles.orderContainer}>
      <form onSubmit={formik.handleSubmit} className={styles.formWrapper}>
        <div className={styles.dateWrapper}>
          <div className={styles.dateContainer}>
            <span className={styles.dateLabel}>
              {t('start_date_of_request_creation')}
            </span>
            <div className={styles.dateBox}>
              <DatePicker
                name="start_date"
                className="datepicker"
                format="DD.MM.YYYY"
                value={
                  formik.values.dates.start_date
                    ? dayjs(formik.values.dates.start_date)
                    : null
                }
                onChange={date => {
                  formik.setFieldValue(
                    'dates.start_date',
                    date
                      ? dayjs(date)
                          .hour(0)
                          .minute(0)
                          .second(1)
                          .format('YYYY-MM-DD HH:mm:ss')
                      : null
                  );
                }}
                placeholder={t('date')}
                disabledDate={(current: Dayjs) =>
                  formik.values.dates.end_date
                    ? current &&
                      current > dayjs(formik.values.dates.end_date).endOf('day')
                    : false
                }
                onBlur={() => formik.setFieldTouched('dates.start_date', true)}
              />
              {formik.errors.dates?.start_date && (
                <FilterErrorMessage message={formik.errors.dates?.start_date} />
              )}
            </div>
          </div>
          <div className={styles.dateContainer}>
            <span className={styles.dateLabel}>
              {t('end_date_of_request_start')}
            </span>
            <div className={styles.dateBox}>
              <DatePicker
                name="end_date"
                className="datepicker"
                format="DD.MM.YYYY"
                value={
                  formik.values.dates.end_date
                    ? dayjs(formik.values.dates.end_date)
                    : null
                }
                onChange={date => {
                  formik.setFieldValue(
                    'dates.end_date',
                    date
                      ? dayjs(date).endOf('day').format('YYYY-MM-DD HH:mm:ss')
                      : null
                  );
                }}
                placeholder={t('date')}
                disabledDate={(current: Dayjs) =>
                  formik.values.dates.start_date
                    ? current &&
                      current <
                        dayjs(formik.values.dates.start_date).startOf('day')
                    : false
                }
                onBlur={() => formik.setFieldTouched('dates.end_date', true)}
              />
              {formik.errors.dates?.end_date && (
                <FilterErrorMessage message={formik.errors.dates?.end_date} />
              )}
            </div>
          </div>
        </div>
        <div className={styles.operators}>
          <div className={styles.operatorWrapper}>
            <CustomSelect
              label={t('request_category')}
              value={formik.values.cancel_orders.cancel_by}
              className={styles.topSelect}
              options={modulesOptions}
              placeholder={t('select_categories')}
              onChange={value =>
                formik.setFieldValue('cancel_orders.cancel_by', value)
              }
              multiselect
              error={
                formik.touched.cancel_orders?.cancel_by
                  ? (formik.errors.cancel_orders?.cancel_by as string)
                  : undefined
              }
            />
          </div>
          <div className={styles.operatorWrapper}>
            <span className={styles.operatorsLabel}>
              {t('number_of_requests_created')}
            </span>
            <div className={styles.operatorLine}>
              <CustomSelect
                value={formik.values.cancel_orders.condition}
                options={[
                  { id: 1, display_name: `${t('more')}`, name: '>' },
                  { id: 2, display_name: `${t('less')}`, name: '<' },
                  { id: 3, display_name: `${t('equal')}`, name: '=' },
                ]}
                placeholder={t('select_operator')}
                onChange={value =>
                  formik.setFieldValue('cancel_orders.condition', value)
                }
                onTouch={() =>
                  formik.setFieldTouched('values.cancel_orders.condition', true)
                }
                error={formik.errors.cancel_orders?.condition?.name}
              />
              <InputBar
                value={formik.values.cancel_orders.count}
                name="cancel_orders.count"
                onChange={formik.handleChange}
                classNameInputWrapper={styles.input}
                placeholder={t('enter_the_number')}
                label=""
                errors={formik.errors.cancel_orders?.count}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.cancel_orders?.count &&
                  Boolean(formik.errors.cancel_orders?.count)
                }
              />
            </div>
          </div>
        </div>
        <FilterButtons
          setIsModalOpen={setIsStatusUsersModalOpen}
          isAnyFieldFilled={isAnyFieldFilled}
          selectedUsers={selectedUsers}
          resetForm={handleResetForm}
          isLoading={isUsersLoading}
        />
      </form>
    </div>
  );
};

//
