import { useNavigate } from 'react-router-dom';
import { useToastError } from '~/utils/useToastError';
import * as yup from 'yup';
import { Formik, FormikValues } from 'formik';
import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { InputBar, Button } from '~/ui';
import styles from './QuestionnaireForm.module.scss';
import cn from 'classnames';
import QuestionsList from './QuestionsList';
import { instance } from '~/utils/api/api';
import { useEffect, useState } from 'react';
import { Modal } from '../Shared/Modal/Modal';
import { getQuestionPayload } from '~/pages/EditQuestionnarie/EditQuestionnaireForm/utils';
import StatIcon from '~/assets/svg/questionnaries/stat-icon.svg?react';
import CreateQuestionForm, {
  QuestionItem,
} from './CreateQuestionForm/CreateQuestionForm';
import EditQuestionForm, {
  QuestionValues,
} from '../EditQuestionForm/EditQuestionForm';
import { useTranslation } from 'react-i18next';
import { useToastSuccess } from '~/utils/useToastSuccess';
import CustomQuerySelect from '../Shared/CustomQuerySelect/CustomQuerySelect';
import CustomSelect from '../Shared/CustomSelect/CustomSelect';

interface ITypeOption {
  id: number;
  name: string;
}

export interface IVariant {
  id: number;
  name: string;
  question_close_id?: number;
}

const QuestionnaireSchema = yup.object({}).shape({
  name: yup.string().required('questionnaire_name_validate'),
  type: yup.object().shape({
    id: yup.number().required('questionnaire_type_validate'),
    name: yup.string().required('questionnaire_type_validate'),
  }),
  publication_date: yup
    .mixed<dayjs.Dayjs>()
    .nullable()
    .required('date_start_validate'),
  removal_date: yup
    .mixed<dayjs.Dayjs>()
    .nullable()
    .required('date_end_validate'),
  accommodation_ids: yup
    .array()
    .of(yup.number().required())
    .min(1, 'accommodations_validate'),
  licensor: yup
    .string()
    .max(50, 'licensor_length_validate')
    .required('licensor_validate'),
});

export interface QuestionnairePayload {
  name: string;
  publication_date: Dayjs | null;
  removal_date: Dayjs | null;
  type: {
    id: number;
    name: string;
  };
  licensor: string;
  questionnaire_accommodations: { id: number; name: string }[];
  questionnaire_organizations: { id: number; name: string }[];
}

const QuestionnaireForm = () => {
  const toastError = useToastError();
  const [typeOptions, setTypeOptions] = useState<ITypeOption[] | []>([]);
  const [questions, setQuestions] = useState<QuestionItem[]>([]);
  const [isAddQuestionModalOpen, setIsAddQuestionModalOpen] = useState(false);
  const [isEditQuestionModalOpen, setIsEditQuestionModalOpen] = useState(false);
  const [editingQuestion, setEditingQuestion] = useState<QuestionItem | null>(
    null
  );
  const toastSuccess = useToastSuccess();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOpenAddQuestionModal = () => {
    setIsAddQuestionModalOpen(true);
  };

  const handleCloseAddQuestionModal = () => {
    setIsAddQuestionModalOpen(false);
  };

  const handleOpenEditQuestionModal = (id: number) => {
    setEditingQuestion(questions.find(question => question.id === id) || null);
    setIsEditQuestionModalOpen(true);
  };

  const handleCloseEditQuestionModal = () => {
    setIsEditQuestionModalOpen(false);
  };

  const handleDeleteQuestion = async (id: number) => {
    const filteredQuestions = questions.filter(question => question.id !== id);
    setQuestions(filteredQuestions);
  };

  const handleCreateQuestion = async (
    values: QuestionValues,
    formik: FormikValues,
    initialValues: QuestionValues
  ) => {
    const payload = getQuestionPayload(values) as QuestionItem;
    if (!payload) return;
    const newQuestion = { ...payload, id: questions.length };

    setQuestions([...questions, newQuestion]);
    formik.resetForm({ values: initialValues });
    handleCloseAddQuestionModal();
  };

  const initialValues = {
    name: '',
    type: {
      id: 0,
      name: '',
    },
    publication_date: null,
    removal_date: null,
    licensor: '',
    group_send: '1',
    questionnaire_accommodations: [],
    questionnaire_organizations: [],
  };

  const loadTypeOptions = async () => {
    try {
      const response = await instance.get('admin/questionnaires-type');
      setTypeOptions(response.data);
    } catch (error) {
      toastError(error);
    }
  };

  const handleSubmit = async (
    values: QuestionnairePayload,
    formik: FormikValues
  ) => {
    try {
      const {
        name,
        publication_date,
        removal_date,
        type,
        licensor,
        questionnaire_accommodations,
        questionnaire_organizations,
      } = values;

      const payload = {
        name,
        type_id: type.id,
        accommodation_ids: questionnaire_accommodations.map(
          accommodation => accommodation.id
        ),
        publication_date: dayjs(publication_date)
          .hour(0)
          .minute(0)
          .second(1)
          .format('YYYY-MM-DD HH:mm:ss'),
        removal_date: dayjs(removal_date)
          .endOf('day')
          .format('YYYY-MM-DD HH:mm:ss'),
        licensor: licensor,
        organization_ids: questionnaire_organizations.map(
          organization => organization.id
        ),
      };
      const response = await instance.post('admin/questionnaires', payload);
      const questionsWithquestionnaireId = questions.map(question => ({
        ...question,
        question_data: {
          ...question.question_data,
          variants: question.question_data.question_close_variants,
        },
        questionnaire_id: response.data.id,
      }));
      await instance.post('admin/questions', questionsWithquestionnaireId);
      toastSuccess(t('questionnaire_created'));
      formik.resetForm({ values: initialValues });
      navigate('/questionnaires');
    } catch (error) {
      toastError(error);
    }
  };

  const handleUpdateQuestion = (
    values: any,
    formik: FormikValues,
    initialValues: any
  ) => {
    const payload: QuestionItem = getQuestionPayload(values) as QuestionItem;
    if (!payload) return;

    const updatedQuestions = questions.map(question => {
      if (question.id === editingQuestion?.id) {
        return {
          ...question,
          ...payload,
        };
      }
      return question;
    });

    setQuestions(updatedQuestions);
    formik.resetForm({ values: initialValues });
    handleCloseEditQuestionModal();
  };

  useEffect(() => {
    loadTypeOptions();
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={QuestionnaireSchema}
      onSubmit={(values, formik) => handleSubmit(values, formik)}
    >
      {formik => {
        return (
          <form className={styles.container} onSubmit={formik.handleSubmit}>
            <div className={styles.formInputs}>
              <div className={styles.group}>
                <InputBar
                  name="name"
                  label={t('anquette_name')}
                  placeholder={t('enter_anquette_name')}
                  value={formik.values.name}
                  disabled={false}
                  star={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  errors={t(formik.errors.name || '')}
                />
                <InputBar
                  name="licensor"
                  label={t('licensor_name')}
                  placeholder={t('enter_licensor_name')}
                  value={formik.values.licensor}
                  disabled={false}
                  star={true}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.licensor && Boolean(formik.errors.licensor)
                  }
                  errors={t(formik.errors.licensor || '')}
                />
              </div>
              <div className={styles.group}>
                <div className={styles.group__item}>
                  <CustomSelect
                    label={t('questionnaire_subject')}
                    placeholder={t('validate_subject')}
                    value={formik.values.type}
                    onChange={value => formik.setFieldValue('type', value)}
                    options={typeOptions}
                    required
                    classNameLabel={styles.typeSelect}
                    error={t(formik.errors.type?.name || '')}
                  />
                </div>

                <div className={cn(styles.group__item, styles.field)}>
                  <div className={styles.field__label}>
                    {t('date')}
                    <span className={styles.required}>*</span>
                  </div>
                  <DatePicker.RangePicker
                    name="dates"
                    className="datepicker"
                    format="DD.MM.YYYY"
                    value={[
                      formik.values.publication_date,
                      formik.values.removal_date,
                    ]}
                    onChange={dates => {
                      if (dates) {
                        formik.setFieldValue('publication_date', dates[0]);
                        formik.setFieldValue('removal_date', dates[1]);
                      } else {
                        formik.setFieldValue('publication_date', null);
                        formik.setFieldValue('removal_date', null);
                      }
                    }}
                    placeholder={[
                      t('start_date_anquette'),
                      t('end_date_anquette'),
                    ]}
                    disabledDate={(current: Dayjs) =>
                      current && current < dayjs().startOf('day')
                    }
                    onBlur={() => {
                      formik.setFieldTouched('publication_date', true);
                      formik.setFieldTouched('removal_date', true);
                    }}
                  />
                  <div className={styles.dateErrors}>
                    <span className={styles.errorText}>
                      {formik.touched.publication_date &&
                        Boolean(formik.errors.publication_date) &&
                        t('date_start_validate')}
                    </span>
                    <span className={styles.errorText}>
                      {formik.touched.publication_date &&
                        Boolean(formik.errors.publication_date) &&
                        t('date_end_validate')}
                    </span>
                  </div>
                </div>
              </div>

              <div className={styles.selectWrappers}>
                <CustomQuerySelect
                  label={t('accommodation_facility')}
                  onChange={value => {
                    formik.setFieldValue('questionnaire_accommodations', value);
                  }}
                  value={formik.values.questionnaire_accommodations}
                  query="admin/accommodations"
                  queryKey={[
                    'accommodations-100',
                    {
                      sort: '',
                      perPage: 100,
                      field: '',
                      page: 1,
                    },
                  ]}
                  params={{
                    sort: '',
                    perPage: 100,
                    field: '',
                    page: 1,
                  }}
                  required
                  placeholder={t('to_choose')}
                  multiselect
                  isSearchEnabled
                  error={t(
                    formik.errors.questionnaire_accommodations as string
                  )}
                />
              </div>

              <CustomQuerySelect
                label={t('organizations')}
                query="admin/organization"
                onChange={value => {
                  formik.setFieldValue('questionnaire_organizations', value);
                }}
                placeholder={t('validate_organizations')}
                value={formik.values.questionnaire_organizations}
                isSearchEnabled
                queryKey={[
                  'organizations',
                  {
                    sort: '',
                    perPage: 100,
                    field: '',
                    page: 1,
                  },
                ]}
                params={{
                  sort: '',
                  perPage: 100,
                  field: '',
                  page: 1,
                }}
                required
                multiselect
              />
            </div>

            <div className={styles.questionsTableContainer}>
              <QuestionsList
                questions={questions}
                clickAddQuestion={handleOpenAddQuestionModal}
                clickDeleteQuestion={handleDeleteQuestion}
                clickEditButton={handleOpenEditQuestionModal}
              />
            </div>

            <div className={styles.formControl}>
              <div className={styles.submitContainer}>
                <Button
                  type="submit"
                  text={t('save_n_exit')}
                  disabled={formik.isSubmitting || !formik.isValid}
                />
              </div>
              <div className={styles.submitContainer}>
                <Button
                  type="button"
                  text={t('anquette_results')}
                  disabled
                  className={styles.statsButton}
                  Icon={StatIcon}
                />
              </div>
            </div>
            <Modal
              isOpen={isAddQuestionModalOpen}
              onClose={handleCloseAddQuestionModal}
              modalStyles={styles.questionModal}
            >
              <CreateQuestionForm
                handleCloseCreate={handleCloseAddQuestionModal}
                clickAddQuestion={handleCreateQuestion}
              />
            </Modal>

            <Modal
              isOpen={isEditQuestionModalOpen}
              onClose={handleCloseEditQuestionModal}
              modalStyles={styles.questionModal}
            >
              {editingQuestion && (
                <EditQuestionForm
                  question={editingQuestion}
                  handleCloseCreate={handleCloseEditQuestionModal}
                  clickUpdateQuestion={handleUpdateQuestion}
                />
              )}
            </Modal>
          </form>
        );
      }}
    </Formik>
  );
};

export default QuestionnaireForm;
